import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import TableFeatures from 'components/Outdoor_Cameras/IN-9420_WQHD/Product_Features/TableFeatures';
import TableStatus from 'components/Outdoor_Cameras/IN-9420_WQHD/Product_Features/TableStatus';
import NavButtons from 'components/Outdoor_Cameras/IN-9420_WQHD/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "IN-9420 2k+ WQHD Manual Product Features",
  "path": "/Outdoor_Cameras/IN-9420_WQHD/Product_Features/",
  "dateChanged": "2017-12-12",
  "author": "Mike Polinowski",
  "excerpt": "The IN-9420 2k+ WQHD is the first INSTAR Outdoor IP Surveillance PTZ camera, utilizing our new SONY STARVIS 1440p / WQHD image sensor.",
  "image": "./P_SearchThumb_IN-9020HD_Features.png",
  "social": "/images/Search/P_SearchThumb_IN-9020HD_Features.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-IN-9020HD_white.webp",
  "chapter": "Outdoor Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title='IN-9420 2k+ WQHD Manual Product Features' dateChanged='2017-12-12' author='Mike Polinowski' tag='INSTAR IP Camera' description='The IN-9420 2k+ WQHD is the first INSTAR Outdoor IP Surveillance PTZ camera, utilizing our new SONY 1440p / fullHD image sensor.' image='/images/Search/P_SearchThumb_IN-9020HD_Features.png' twitter='/images/Search/P_SearchThumb_IN-9020HD_Features.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Aussenkameras/IN-9420_WQHD/Produkt_Features/' locationFR='/fr/Outdoor_Cameras/IN-9420_WQHD/Product_Features/' crumbLabel="Features" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h2 {...{
      "id": "in-9420-2k-wqhd-manual",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#in-9420-2k-wqhd-manual",
        "aria-label": "in 9420 2k wqhd manual permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IN-9420 2k+ WQHD Manual`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "674px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/68f0debddef24edce0e7bd0041bde022/a6d30/IN-9420_WQHD_Product-Overview.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "78.69565217391303%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAQABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAECBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAe9LDQP/xAAVEAEBAAAAAAAAAAAAAAAAAAAgQf/aAAgBAQABBQKn/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFBABAAAAAAAAAAAAAAAAAAAAIP/aAAgBAQAGPwJf/8QAGRABAAIDAAAAAAAAAAAAAAAAAAFBESFx/9oACAEBAAE/ITWLQtxD/9oADAMBAAIAAwAAABDwD//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABgQAQADAQAAAAAAAAAAAAAAAAEAITER/9oACAEBAAE/EFKX2KEhq5iOagIOLuYvZ//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/68f0debddef24edce0e7bd0041bde022/e4706/IN-9420_WQHD_Product-Overview.avif 230w", "/en/static/68f0debddef24edce0e7bd0041bde022/d1af7/IN-9420_WQHD_Product-Overview.avif 460w", "/en/static/68f0debddef24edce0e7bd0041bde022/d1b46/IN-9420_WQHD_Product-Overview.avif 674w"],
              "sizes": "(max-width: 674px) 100vw, 674px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/68f0debddef24edce0e7bd0041bde022/a0b58/IN-9420_WQHD_Product-Overview.webp 230w", "/en/static/68f0debddef24edce0e7bd0041bde022/bc10c/IN-9420_WQHD_Product-Overview.webp 460w", "/en/static/68f0debddef24edce0e7bd0041bde022/853dc/IN-9420_WQHD_Product-Overview.webp 674w"],
              "sizes": "(max-width: 674px) 100vw, 674px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/68f0debddef24edce0e7bd0041bde022/e83b4/IN-9420_WQHD_Product-Overview.jpg 230w", "/en/static/68f0debddef24edce0e7bd0041bde022/e41a8/IN-9420_WQHD_Product-Overview.jpg 460w", "/en/static/68f0debddef24edce0e7bd0041bde022/a6d30/IN-9420_WQHD_Product-Overview.jpg 674w"],
              "sizes": "(max-width: 674px) 100vw, 674px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/68f0debddef24edce0e7bd0041bde022/a6d30/IN-9420_WQHD_Product-Overview.jpg",
              "alt": "IN-9420 2k+ WQHD Produkt Features",
              "title": "IN-9420 2k+ WQHD Produkt Features",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <TableFeatures mdxType="TableFeatures" />
    <h3 {...{
      "id": "status-leds",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#status-leds",
        "aria-label": "status leds permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Status LEDs`}</h3>
    <TableStatus mdxType="TableStatus" />
    <EuiSpacer mdxType="EuiSpacer" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      